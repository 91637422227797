export default [
  {
    path: '/botauto/deposits',
    name: 'botauto-deposits',
    component: () => import('@/views/botauto/DepositList.vue'),
    meta: {
      pageTitle: 'รายการฝากเงิน',
      breadcrumb: [
        {
          text: 'บอทออโต้',
        },
        {
          text: 'รายการฝากเงิน',
          active: true,
        },
      ],
    //   resource: 'ACL',
    //   action: 'read',
    },
  },
  {
    path: '/botauto/withdraws',
    name: 'botauto-withdraws',
    component: () => import('@/views/botauto/WithdrawList.vue'),
    meta: {
      pageTitle: 'รายการถอนเงิน',
      breadcrumb: [
        {
          text: 'บอทออโต้',
        },
        {
          text: 'รายการถอนเงิน',
          active: true,
        },
      ],
    //   resource: 'ACL',
    //   action: 'read',
    },
  },
  {
    path: '/botauto/statements',
    name: 'botauto-statements',
    component: () => import('@/views/botauto/BotStatementList.vue'),
    meta: {
      pageTitle: 'รายการฝากถอนระบบ',
      breadcrumb: [
        {
          text: 'บอทออโต้',
        },
        {
          text: 'รายการฝากถอนระบบ',
          active: true,
        },
      ],
    //   resource: 'ACL',
    //   action: 'read',
    },
  },
  {
    path: '/botauto/bank-statements',
    name: 'botauto-bankstatement',
    component: () => import('@/views/botauto/BankStatementList.vue'),
    meta: {
      pageTitle: 'รายการเดินบัญชี',
      breadcrumb: [
        {
          text: 'บอทออโต้',
        },
        {
          text: 'รายการเดินบัญชี',
          active: true,
        },
      ],
    //   resource: 'ACL',
    //   action: 'read',
    },
  },
  {
    path: '/botauto/huskypay',
    name: 'botauto-huskypay',
    component: () => import('@/views/botauto/HuskyPay.vue'),
    meta: {
      pageTitle: 'รายการเพย์เม้น',
      breadcrumb: [
        {
          text: 'บอทออโต้',
        },
        {
          text: 'รายการเพย์เม้น',
          active: true,
        },
      ],
    //   resource: 'ACL',
    //   action: 'read',
    },
  },
  {
    path: '/botauto/incomming-sms',
    name: 'botauto-sms',
    component: () => import('@/views/botauto/IncomingSMSList.vue'),
    meta: {
      pageTitle: 'รายการ SMS',
      breadcrumb: [
        {
          text: 'บอทออโต้',
        },
        {
          text: 'รายการ SMS',
          active: true,
        },
      ],
    //   resource: 'ACL',
    //   action: 'read',
    },
  },
  {
    path: '/botauto/manage-bankaccount/:bankaccount',
    name: 'manage-bankaccount-detail',
    component: () => import('@/views/botauto/ManageBankAccountDetail.vue'),
    meta: {
      pageTitle: 'ข้อมูลบัญชีธนาคาร',
      breadcrumb: [
        {
          text: 'บอทออโต้',
        },
        {
          text: 'จัดการบัญชีธนาคาร',
          active: true,
        },
      ],
    //   resource: 'ACL',
    //   action: 'read',
    },
  },
  {
    path: '/botauto/manage-bankaccount',
    name: 'botauto-bankaccounts',
    component: () => import('@/views/botauto/ManageBankAccount.vue'),
    meta: {
      pageTitle: 'ข้อมูลบัญชีธนาคาร',
      breadcrumb: [
        {
          text: 'บอทออโต้',
        },
        {
          text: 'จัดการบัญชีธนาคาร',
          active: true,
        },
      ],
    //   resource: 'ACL',
    //   action: 'read',
    },
  },
  {
    path: '/botauto/slip-deposit',
    name: 'botauto-qr-deposits',
    component: () => import('@/views/botauto/QRDeposit.vue'),
    meta: {
      pageTitle: 'เติมเงินด้วยสลิปธนาคาร',
      breadcrumb: [
        {
          text: 'บอทออโต้',
        },
        {
          text: 'เติมเงินด้วยสลิปธนาคาร',
          active: true,
        },
      ],
    //   resource: 'ACL',
    //   action: 'read',
    },
  },
]
