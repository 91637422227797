export default [
  {
    path: '/settings/manage-admin/:admin',
    name: 'manage-admin-detail',
    component: () => import('@/views/settings/ManageAdminDetail.vue'),
    meta: {
      pageTitle: 'จัดการแอดมิน',
      breadcrumb: [
        {
          text: 'ตั้งค่า',
        },
        {
          text: 'จัดการแอดมิน',
          active: true,
        },
      ],
    //   resource: 'ACL',
    //   action: 'read',
    },
  },
  {
    path: '/settings/manage-admin',
    name: 'manage-admin',
    component: () => import('@/views/settings/ManageAdmin.vue'),
    meta: {
      pageTitle: 'จัดการแอดมิน',
      breadcrumb: [
        {
          text: 'ตั้งค่า',
        },
        {
          text: 'จัดการแอดมิน',
          active: true,
        },
      ],
    //   resource: 'ACL',
    //   action: 'read',
    },
  },
  {
    path: '/settings/promotions/:promotionId',
    name: 'setting-promotion-detail',
    component: () => import('@/views/settings/ManagePromotionDetail.vue'),
    meta: {
      pageTitle: 'ตั้งค่าโปรโมชั่น',
      breadcrumb: [
        {
          text: 'ตั้งค่า',
        },
        {
          text: 'ตั้งค่าโปรโมชั่น',
          active: true,
        },
      ],
    //   resource: 'ACL',
    //   action: 'read',
    },
  },
  {
    path: '/settings/promotions',
    name: 'setting-promotion',
    component: () => import('@/views/settings/ManagePromotion.vue'),
    meta: {
      pageTitle: 'ตั้งค่าโปรโมชั่น',
      breadcrumb: [
        {
          text: 'ตั้งค่า',
        },
        {
          text: 'ตั้งค่าโปรโมชั่น',
          active: true,
        },
      ],
    //   resource: 'ACL',
    //   action: 'read',
    },
  },
  {
    path: '/settings/system',
    name: 'setting-system',
    component: () => import('@/views/settings/SystemSettings.vue'),
    meta: {
      pageTitle: 'ตั้งค่าระบบ',
      breadcrumb: [
        {
          text: 'ตั้งค่า',
        },
        {
          text: 'ระบบ',
          active: true,
        },
      ],
    //   resource: 'ACL',
    //   action: 'read',
    },
  },
  {
    path: '/settings/member-groups/add-new',
    name: 'manage-member-group-detail-add',
    component: () => import('@/views/settings/ManageMemberGroupDetail.vue'),
    meta: {
      pageTitle: 'เพิ่มกลุ่มลูกค้าใหม่',
      breadcrumb: [
        {
          text: 'ตั้งค่า',
        },
        {
          text: 'เพิ่มกลุ่มลูกค้าใหม่',
          active: true,
        },
      ],
    //   resource: 'ACL',
    //   action: 'read',
    },
  },
  {
    path: '/settings/member-groups/:groupId',
    name: 'manage-member-group-detail',
    component: () => import('@/views/settings/ManageMemberGroupDetail.vue'),
    meta: {
      pageTitle: 'ตั้งค่ากลุ่มลูกค้า',
      breadcrumb: [
        {
          text: 'ตั้งค่า',
        },
        {
          text: 'ตั้งค่ากลุ่มลูกค้า',
          active: true,
        },
      ],
    //   resource: 'ACL',
    //   action: 'read',
    },
  },
  {
    path: '/settings/member-groups',
    name: 'manage-member-group',
    component: () => import('@/views/settings/ManageMemberGroup.vue'),
    meta: {
      pageTitle: 'กลุ่มลูกค้า',
      breadcrumb: [
        {
          text: 'ตั้งค่า',
        },
        {
          text: 'กลุ่มลูกค้า',
          active: true,
        },
      ],
    //   resource: 'ACL',
    //   action: 'read',
    },
  },
  {
    path: '/settings/popups',
    name: 'manage-popups',
    component: () => import('@/views/settings/ManagePopup.vue'),
    meta: {
      pageTitle: 'ป๊อปอัพหน้าเว็บ',
      breadcrumb: [
        {
          text: 'ตั้งค่า',
        },
        {
          text: 'ป๊อปอัพหน้าเว็บ',
          active: true,
        },
      ],
    //   resource: 'ACL',
    //   action: 'read',
    },
  },

]
