import gameProvidersReport from '../../../game-provider-settings'

const agentReportRoute = []
Object.keys(gameProvidersReport).forEach(gp => {
  agentReportRoute.push({
    path: `/reports/game-provider-agents/${gp.toLowerCase()}`,
    name: 'report-backoffice-gp',
    component: () => import('@/views/report/ReportGameProvider.vue'),
    meta: {
      pageTitle: 'รายงานค่ายเกมส์',
      breadcrumb: [
        {
          text: 'รายงาน',
        },
        {
          text: `รายงานค่ายเกมส์ ${gameProvidersReport[gp].name}`,
          active: true,
        },
      ],
    },
  })
})

export default [
  {
    path: '/reports/member',
    name: 'report-member',
    component: () => import('@/views/report/ReportMember.vue'),
    meta: {
      pageTitle: 'รายงานสมาชิก',
      breadcrumb: [
        {
          text: 'รายงาน',
        },
        {
          text: 'รายงานสมาชิก',
          active: true,
        },
      ],
    //   resource: 'ACL',
    //   action: 'read',
    },
  },
  {
    path: '/report/paidpromo',
    name: 'report-paidpromotion',
    component: () => import('@/views/report/ReportPaidPromotion.vue'),
    meta: {
      pageTitle: 'รายงานจ่ายโปรโมชั่น',
      breadcrumb: [
        {
          text: 'รายงาน',
        },
        {
          text: 'รายงานจ่ายโปรโมชั่น',
          active: true,
        },
      ],
    //   resource: 'ACL',
    //   action: 'read',
    },
  },
  {
    path: '/report/user-winloss',
    name: 'report-user-winloss',
    component: () => import('@/views/report/ReportWinlossUser.vue'),
    meta: {
      pageTitle: 'รายงานยอดแพ้ชนะ',
      breadcrumb: [
        {
          text: 'รายงาน',
        },
        {
          text: 'รายงานยอดแพ้ชนะรายสมาชิก',
          active: true,
        },
      ],
    //   resource: 'ACL',
    //   action: 'read',
    },
  },
  {
    path: '/report/gameprovider-winloss',
    name: 'report-gameprovider-winloss',
    component: () => import('@/views/report/ReportWinlossGameProvider.vue'),
    meta: {
      pageTitle: 'รายงานยอดแพ้ชนะ',
      breadcrumb: [
        {
          text: 'รายงาน',
        },
        {
          text: 'รายงานยอดแพ้ชนะรายค่ายเกมส์',
          active: true,
        },
      ],
    //   resource: 'ACL',
    //   action: 'read',
    },
  },
  {
    path: '/report/daily-summary',
    name: 'report-daily-summary',
    component: () => import('@/views/report/ReportDailySummary.vue'),
    meta: {
      pageTitle: 'รายงานสรุปยอดรายวัน',
      breadcrumb: [
        {
          text: 'รายงาน',
        },
        {
          text: 'รายงานสรุปยอดรายวัน',
          active: true,
        },
      ],
    //   resource: 'ACL',
    //   action: 'read',
    },
  },
  {
    path: '/report/vip-members',
    name: 'report-vip',
    component: () => import('@/views/report/ReportVIP.vue'),
    meta: {
      pageTitle: 'รายงานสรุปยอด VIP',
      breadcrumb: [
        {
          text: 'รายงาน',
        },
        {
          text: 'รายงานสรุปยอด VIP',
          active: true,
        },
      ],
    //   resource: 'ACL',
    //   action: 'read',
    },
  },
  {
    path: '/report/account-summary',
    name: 'report-account-summary',
    component: () => import('@/views/report/ReportAccountSummary.vue'),
    meta: {
      pageTitle: 'รายงานสรุปยอดบัญชี',
      breadcrumb: [
        {
          text: 'รายงาน',
        },
        {
          text: 'รายงานสรุปยอดบัญชี',
          active: true,
        },
      ],
    //   resource: 'ACL',
    //   action: 'read',
    },
  },
  {
    path: '/reports/game-provider-agents',
    name: 'report-backoffice-gp',
    component: () => import('@/views/report/ReportGameProvider.vue'),
    meta: {
      pageTitle: 'รายงานค่ายเกมส์',
      breadcrumb: [
        {
          text: 'รายงาน',
        },
        {
          text: 'ทางเข้าหลังบ้านค่ายเกมส์',
          active: true,
        },
      ],
    },
  },
]
